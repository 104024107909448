
import { Tabs } from "@num/component-library";
import Vue from "vue";

import Container from "@/components/Container.vue";
import Hero from "@/components/Hero.vue";

import { getMobileOperatingSystem } from "@/utils/utils";

export default Vue.extend({
  components: {
    Container,
    Hero,
    Tabs,
  },
  data() {
    return {
      selectedTab: getMobileOperatingSystem() || "Android",
      tabs: ["Android", "iOS"],
    };
  },
  created() {
    // set the URL as the homepage so that when the user goes to add to
    // homescreen it will load the homepage in the resulting PWA
    // src: https://stackoverflow.com/a/56691294/827129
    history.pushState({}, "", "/");
  },
});
